export function formatTimeUntil(date) {
	const now = new Date()
	const targetDate = new Date(date)
	const diffInMs = targetDate - now

	const diffInMinutes = Math.floor(diffInMs / (1000 * 60))
	const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))
	const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))

	if (diffInDays > 0) {
		return `${diffInDays} дн.`
	} else if (diffInHours > 0) {
		return `${diffInHours} ч.`
	} else {
		return `${diffInMinutes} мин.`
	}
}
