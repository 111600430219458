import React from 'react'
import './PrizeModal.css' // Подключение стилей для модального окна

const PrizeModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null

	return (
		<div className='modal-overlay' onClick={onClose}>
			<div className='modal-content' onClick={e => e.stopPropagation()}>
				<button className='close-button' onClick={onClose}>
					&times;
				</button>
				<h2>Список призов</h2>
				<ul>
					<li>Увеличение губ БЕСПЛАТНО</li>
					<li>
						Ботулинотерапия (глаза или лоб) 2.900 <s>5.900</s>
					</li>
					<li>Мезотерапия 1 процедура БЕСПЛАТНО</li>
					<li>
						Увеличение губ 6.900 <s>9.900</s>
					</li>
					<li>
						Биоревитализация 2.900 <s>5.700</s>
					</li>
					<li>
						Увеличение губ 5.900 <s>9.900</s>
					</li>
					<li>
						Увеличение губ + ботулинотерапия Full face 11.900 <s>19.700</s>
					</li>
					<li>
						Ботулинотерапия Full Face 5.900 <s>10.900</s>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default PrizeModal
