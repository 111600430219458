import React from 'react'
import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Roulette from './components/screens/Roulette/Roulette'
import Home from "./components/screens/Home/Home";
import Sending from "./components/screens/Sending/Sending";
import CreateRaffle from "./components/screens/Raffle/Raffle";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Roulette/>
	},
	{
		path: '/admin',
		element: <Home/>
	},
	{
		path: '/raffle',
		element: <CreateRaffle/>
	},
	{
		path: '/sending',
		element: <Sending/>
	}
])

function App() {
	return (
		<RouterProvider router={router}/>
	)
}

export default App
