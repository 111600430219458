import React from 'react'
import './Button.scss'

const Button = ({ text, onClick, className }) => {
	return (
		<button
			className={`glamorous-button ${className ? className : ''}`}
			onClick={onClick}
		>
			{text}
		</button>
	)
}

export default Button
